import { useStoreActions } from 'easy-peasy';
import { useEffect, useState } from 'react';

import Loading from "../../components/ui/Loading/Loading";

const LoadData = ({account}) => {
  const [loading, setLoading] = useState(false);

  const fetchWellnessPersonalSettings = useStoreActions((actions) => actions.wellness.plan.fetchPersonalSettings);
  const setWellnessPersonalSettings = useStoreActions((actions) => actions.wellness.plan.setPersonalSettings);

  const fetchWellnessDietarySettings = useStoreActions((actions) => actions.wellness.plan.fetchDietarySettings);
  const setWellnessDietarySettings = useStoreActions((actions) => actions.wellness.plan.setDietarySettings);

  const fetchWellnessActivities = useStoreActions((actions) => actions.wellness.activity.fetchActivities);
  const setWellnessActivities = useStoreActions((actions) => actions.wellness.activity.setActivities);

  const fetchProfileByUid = useStoreActions((actions) => actions.profile.fetchProfileByUid);
  const setMyProfile = useStoreActions((actions) => actions.profile.setMyProfile);
  const saveProfile = useStoreActions((actions) => actions.profile.saveProfile);

  const fetchProfiles = useStoreActions((actions) => actions.profile.fetchProfiles);
  const setRecommendedProfiles = useStoreActions((actions) => actions.profile.setRecommendedProfiles);

  const fetchData = async () => {
    if (account?.uid) {
      setLoading(true);
  
      try {
        console.log('[LOAD DATA] Fetching wellness personal settings for account', account?.uid);
        const wellnessPlanPersonalSettings = await fetchWellnessPersonalSettings(account?.uid);
        await setWellnessPersonalSettings(wellnessPlanPersonalSettings);

        console.log('[LOAD DATA] Fetching wellness dietary settings for account', account?.uid);
        const wellnessPlanDietarySettings = await fetchWellnessDietarySettings(account?.uid);
        await setWellnessDietarySettings(wellnessPlanDietarySettings);

        console.log('[LOAD DATA] Fetching wellness activity data for account', account?.uid);
        const wellnessActivities = await fetchWellnessActivities(account?.uid);
        await setWellnessActivities(wellnessActivities);

        console.log('[LOAD DATA] Fetching my profile data for account', account?.uid);
        const myProfile = await fetchProfileByUid(account?.uid);
        if (!myProfile) {
          await createProfile(account);
        } else {
          await setMyProfile(myProfile);
        }

        console.log('[LOAD DATA] Fetching recommended profiles');
        const recommendedProfiles = await fetchProfiles();
        await setRecommendedProfiles(recommendedProfiles);
      } catch (error) {
        console.error('[LOAD DATA] An error occurred', error);
      } finally {
        setLoading(false);
      }
    }
  }

  const createProfile = async (account) => {
    console.log('[LOGIN] Creating new profile');
    const profile = await saveProfile({
      uid: account.uid,
      displayName: account.firstName + ' ' + account.lastName,
      description: null,
      username: (account?.firstName?.trim() + account?.lastName?.trim()).toLowerCase(),
      photoURL: account.photoURL,
      isPremium: account.isPremium
    });

    await setMyProfile(profile);
  }

  useEffect(() => {
    fetchData()
    .catch('[LOAD DATA] An error occurred when fetching the data', console.error);
  }, [account]);

  return (
    <div className={loading ? 'block' : 'hidden' }>
      <Loading className="h-screen" />
    </div>
  );
}
 
export default LoadData;