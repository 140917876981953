import { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import Splash from "../../components/ui/Splash/Splash";
import Modal from '../../components/ui/Modal/Modal';
import Button from '../../components/ui/Form/Button/Button';
import Notification from '../../components/ui/Notification/Notification';
import Loading from '../../components/ui/Loading/Loading';

import WellnessDashboard from "../../pages/Wellness/WellnessDashboard";
import WellnessFormBudget from "../../components/form/Wellness/WellnessForm_Budget";
import WellnessFormGoals from "../../components/form/Wellness/WellnessForm_Goals";
import WellnessFormGoalsWeightStart from '../../components/form/Wellness/WellnessForm_Goals_Weight_Start';
import WellnessFormGoalsWeight from '../../components/form/Wellness/WellnessForm_Goals_Weight';
import WellnessFormGoalsWeightEnd from '../../components/form/Wellness/WellnessForm_Goals_Weight_End';
import WellnessFormGoalsMuscle from '../../components/form/Wellness/WellnessForm_Goals_Muscle';
import WellnessFormHabits from "../../components/form/Wellness/WellnessForm_Habits";
import WellnessFormHistory from "../../components/form/Wellness/WellnessForm_History";
import WellnessFormPersonalInfo from "../../components/form/Wellness/WellnessForm_Personal_Info";

import Premium from "../../pages/Premium/Premium";

import { askChatGpt } from "../../services/ChatGptService";
import WellnessFormGoalsMuscleEnd from '../../components/form/Wellness/WellnessForm_Goals_Muscle_End';
import WellnessFormActivity from '../../components/form/Wellness/WellnessForm_Activity';
import WellnessFormGoalsWeightWeekly from '../../components/form/Wellness/WellnessForm_Goals_Weight_Weekly';
import WellnessFormAd from '../../components/form/Wellness/WellnessForm_Ad';
import ProgressBar from '../../components/ui/ProgressBar/ProgressBar';

const Wellness = () => { 
  const cleanForm = useStoreState((state) => state.wellness.plan.cleanForm);
  const personalSettings = useStoreState((state) => state.wellness.plan.personalSettings);
  const dietarySettings = useStoreState((state) => state.wellness.plan.dietarySettings);
  const dietaryPlan = useStoreState((state) => state.wellness.plan.dietaryPlan);
  const fitnessSettings = useStoreState((state) => state.wellness.plan.fitnessSettings);
  const fitnessPlan = useStoreState((state) => state.wellness.plan.fitnessPlan);

  const [isSlideOverOpen, setOpenSlideOver] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);

  const [notification, setNotification] = useState(null);

  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setActiveStep(activeStep + 1 < steps?.length ? activeStep + 1 : activeStep);
  } 

  const previousStep = () => {
    setActiveStep(activeStep - 1 >= 0 ? activeStep - 1 : activeStep);
  }

  const onClose = () => {
    setOpenSlideOver(false);
    setActiveStep(0);
  }

  const onPrmeiumClose = () => {
    setIsPremiumModalOpen(false);
  }

  const handleComplete = () => {
    console.log('personalSettings', personalSettings);
    console.log('dietarySettings', dietarySettings);
    console.log('dietaryPlan', dietaryPlan);
    console.log('fitnessSettings', fitnessSettings);
    console.log('fitnessPlan', fitnessPlan);
    setOpenSlideOver(false);
    // setLoading(true);
  }

  useEffect(() => {
  },[cleanForm]);

  const steps = [
    <WellnessFormPersonalInfo />,
    /*
    <WellnessFormGoals control={control} />,
    <WellnessFormGoalsWeightStart control={control} />,
    <WellnessFormGoalsWeight control={control} />,
    <WellnessFormGoalsWeightEnd control={control} />,
    <WellnessFormAd />,
    <WellnessFormGoalsMuscle control={control} />,
    <WellnessFormGoalsMuscleEnd control={control} />,
    <WellnessFormActivity control={control} />,
    <WellnessFormGoalsWeightWeekly control={control} />
    */
    /*
    <WellnessFormHistory control={control} />,
    <WellnessFormHabits control={control} />,
    <WellnessFormBudget control={control} />
    */
  ]

  return (
    <>
      {loading ? 
        <Loading label="Gathering Wellness Plan" className="h-screen" />
      :

        dietaryPlan || fitnessPlan ?
          <WellnessDashboard setOpenSlideOver={setOpenSlideOver} />
        :
          <div>

            <div className="p-2 md:p-5">
              <Splash 
                titleLine1="Welcome to"
                titleLine2="HelloLife Wellness"
                description="We'll help you create the diet that fits your nutritional goals. 
                After we learn a little more about you, we'll create a meal plan filled with 
                custom recipies, show you where to buy the ingredients."
                buttonText="Get started"
                clickEvent={()=> setOpenSlideOver(true)}
              />
            </div>
          
            <Modal
              title="Create Wellness Plan"
              isOpen={isSlideOverOpen}
              onClose={onClose}>

              <ProgressBar progress={
                [{
                  percentage: (activeStep + 1) / steps.length * 100
                }]
              } />
            
              <div className="h-[calc(100vh-205px)] sm:h-[calc(100vh-225px)] overflow-y-auto">
                { steps[activeStep] }
              </div>

              <div className="bg-white dark:bg-slate-900 border-t border-gray-200 dark:border-slate-700 h-[50px] flex-shrink-0 px-4 pt-3">
                <div className="flex justify-between gap-10">

                  <Button
                    onClick={() => previousStep()}
                    disabled={activeStep == 0}
                    className="w-full">
                    Previous
                  </Button>

                  { steps?.length - 1 !== activeStep ?
                    <Button
                      onClick={() => nextStep()}
                      primary={true}
                      disabled={!cleanForm}
                      className="w-full">
                      Next
                    </Button>
                  :
                    <Button
                      onClick={() => handleComplete()}
                      primary={true}
                      disabled={!cleanForm}
                      className="w-full">
                      Build my Plan!
                    </Button>
                  }

                </div>
              </div>
            
            </Modal>

            <Modal
              title="HelloLife Premium"
              isOpen={isPremiumModalOpen}
              onClose={onPrmeiumClose}>
                
              <Premium />

            </Modal>

          </div>
      }

      <Notification data={ notification } />
    </>
  );
}
 
export default Wellness;