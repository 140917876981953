import { action, thunk } from 'easy-peasy';
import { doc, setDoc, collection, addDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../firebase';

const plan = {
  id: null,
  
  cleanForm: false,
  personalSettings: null,
  dietarySettings: null,
  dietaryPlan: null,
  fitnessSettings: null,
  fitnessPlan: null,

  setCleanForm: action((state, payload) => {
    state.cleanForm = payload;
  }),

  setPersonalSettings: action((state, payload) => {
    state.personalSettings = payload;
  }),

  setDietarySettings: action((state, payload) => {
    state.dietarySettings = payload;
  }),

  setDietaryPlan: action((state, payload) => {
    state.dietaryPlan = payload;
  }),

  setFitnessSettings: action((state, payload) => {
    state.fitnessSettings = payload;
  }),

  setFitnessPlan: action((state, payload) => {
    state.fitnessPlan = payload;
  }),

    /* 
      Save personal settings
    */
    savePersonalSettings: action(async (actions, payload) => {
      const account = JSON.parse(localStorage.getItem("account"));
  
      setDoc(doc(firestore, account.uid, "wellness"), payload);
      
      const wellnessDoc = await getDoc(doc(firestore, "accounts", account.uid, "wellness", "personalSettings"));
      if (!wellnessDoc.exists()) {
        console.error('[WELLNESS] Wellness personal settings could not be saved');
      }
  
      return wellnessDoc.data();
    }),
  
    /*
      Get personal settings
    */
    fetchPersonalSettings: thunk(async (actions, state) => {
      const account = JSON.parse(localStorage.getItem("account"));
      if (!account?.uid) {
        return null;
      }
  
      console.log('[WELLNESS] Fetching wellness personal settings for user with ID ' + account.uid);
  
      const docRef = doc(firestore, "accounts", account.uid, "wellness", "personalSettings");
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        console.log('[WELLNESS] Found wellness personal settings for Account with ID ' + account.uid);
      } else {
        console.log('[WELLNESS] Missing wellness personal settings for Account with ID ' + account.uid);
      }
      
      return docSnap.data();
    }),

  /* 
    Save dietary settings
  */
  saveDietarySettings: action(async (actions, payload) => {
    const account = JSON.parse(localStorage.getItem("account"));

    setDoc(doc(firestore, account.uid, "wellness"), payload);
    
    const wellnessDoc = await getDoc(doc(firestore, "accounts", account.uid, "wellness", "dietarySettings"));
    if (!wellnessDoc.exists()) {
      console.error('[WELLNESS] Wellness dietary settings could not be saved');
    }

    return wellnessDoc.data();
  }),

  /*
    Get dietary settings
  */
  fetchDietarySettings: thunk(async (actions, state) => {
    const account = JSON.parse(localStorage.getItem("account"));
    if (!account?.uid) {
      return null;
    }

    console.log('[WELLNESS] Fetching wellness dietary settings for user with ID ' + account.uid);

    const docRef = doc(firestore, "accounts", account.uid, "wellness", "dietarySettings");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('[WELLNESS] Found wellness dietary settings for Account with ID ' + account.uid);
    } else {
      console.log('[WELLNESS] Missing wellness dietary settings for Account with ID ' + account.uid);
    }
    
    return docSnap.data();
  }),

    /* 
      Save dietary plan
    */
    saveDietaryPlan: action(async (actions, payload) => {
      const account = JSON.parse(localStorage.getItem("account"));
  
      setDoc(doc(firestore, account.uid, "wellness"), payload);
      
      const wellnessDoc = await getDoc(doc(firestore, "accounts", account.uid, "wellness", "dietaryPlan"));
      if (!wellnessDoc.exists()) {
        console.error('[WELLNESS] Wellness dietary plan could not be saved');
      }
  
      return wellnessDoc.data();
    }),
  
    /*
      Get dietary plan
    */
    fetchDietaryPlan: thunk(async (actions, state) => {
      const account = JSON.parse(localStorage.getItem("account"));
      if (!account?.uid) {
        return null;
      }
  
      console.log('[WELLNESS] Fetching wellness dietary plan for user with ID ' + account.uid);
  
      const docRef = doc(firestore, "accounts", account.uid, "wellness", "dietaryPlan");
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        console.log('[WELLNESS] Found wellness dietary plan for Account with ID ' + account.uid);
      } else {
        console.log('[WELLNESS] Missing wellness dietary plan for Account with ID ' + account.uid);
      }
      
      return docSnap.data();
    }),

    /* 
      Save fitness settings
    */
    saveFitnessSettings: action(async (actions, payload) => {
      const account = JSON.parse(localStorage.getItem("account"));
  
      setDoc(doc(firestore, account.uid, "wellness"), payload);
      
      const wellnessDoc = await getDoc(doc(firestore, "accounts", account.uid, "wellness", "fitnessSettings"));
      if (!wellnessDoc.exists()) {
        console.error('[WELLNESS] Wellness fitness settings could not be saved');
      }
  
      return wellnessDoc.data();
    }),
  
    /*
      Get fitness settings
    */
    fetchFitnessSettings: thunk(async (actions, state) => {
      const account = JSON.parse(localStorage.getItem("account"));
      if (!account?.uid) {
        return null;
      }
  
      console.log('[WELLNESS] Fetching wellness fitness settings for user with ID ' + account.uid);
  
      const docRef = doc(firestore, "accounts", account.uid, "wellness", "fitnessSettings");
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        console.log('[WELLNESS] Found wellness fitness settings for Account with ID ' + account.uid);
      } else {
        console.log('[WELLNESS] Missing wellness fitness settings for Account with ID ' + account.uid);
      }
      
      return docSnap.data();
    }),

    /* 
    Save fitness plan
    */
    saveFitnessPlan: action(async (actions, payload) => {
      const account = JSON.parse(localStorage.getItem("account"));
  
      setDoc(doc(firestore, account.uid, "wellness"), payload);
      
      const wellnessDoc = await getDoc(doc(firestore, "accounts", account.uid, "wellness", "fitnessPlan"));
      if (!wellnessDoc.exists()) {
        console.error('[WELLNESS] Wellness fitness plan could not be saved');
      }
  
      return wellnessDoc.data();
    }),
  
    /*
      Get fitness plan
    */
    fetchFitnessPlan: thunk(async (actions, state) => {
      const account = JSON.parse(localStorage.getItem("account"));
      if (!account?.uid) {
        return null;
      }
  
      console.log('[WELLNESS] Fetching wellness fitness plan for user with ID ' + account.uid);
  
      const docRef = doc(firestore, "accounts", account.uid, "wellness", "fitnessPlan");
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        console.log('[WELLNESS] Found wellness fitness plan for Account with ID ' + account.uid);
      } else {
        console.log('[WELLNESS] Missing wellness fitness plan for Account with ID ' + account.uid);
      }
      
      return docSnap.data();
    }),

  dietaryPreferencesAndRestrictionOptions: [
    {
      label: "Dairy-free",
      description: null
    },
    {
      label: "Gluten-free",
      description: null
    },
    {
      label: "Vegan",
      description: null
    },
    {
      label: "Vegetarian",
      description: null
    }
  ],

  medicalConditionOptions: [
    {
      label: "Cardiovascular Disease",
      description: null
    },
    {
      label: "Cholesterol",
      description: null
    },
    {
      label: "Diabetes",
      description: null
    },
    {
      label: "Hypertension",
      description: null
    }
  ],

  foodAllergyOptions: [
    {
      label: "Diabetes",
      description: null
    },
    {
      label: "Cardiovascular Disease",
      description: null
    }
  ],

  mealsPerDayOptions: [
    {
      label: "1",
      value: "1"
    },
    {
      label: "2",
      value: "2"
    },
    {
      label: "3",
      value: "3"
    },
    ,
    {
      label: "4",
      value: "4"
    },
    ,
    {
      label: "5",
      value: "5"
    }
  ],

  mealTimingOptions: [
    {
      label: "Intermittent Fasting",
      description: null
    },
    {
      label: "Multiple Small Meals",
      description: null
    }
  ],

  cookingSkillOptions: [
    {
      label: "Beginner",
      value: "Beginner"
    },
    {
      label: "Intermediate",
      value: "Intermediate"
    },
    {
      label: "Advanced",
      value: "Advanced"
    }
  ],

};

export default plan;